
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
  getCurrentInstance ,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: { ChaKanTouSu },
  setup(props, { emit }) {
    const datePickerVisible = ref(false);
    // const {proxy} = getCurrentInstance() as any
    // const instance = getCurrentInstance() as any;
    let data = reactive<any>({
      init: false,
      url: "",
      maps: new Map(),
      data: {},
      option: {},
      title: "",
      total: 0, // 总条目数
      complaintFormStatisticsList:[],
      complaintFormStatisticsCountList:[],
      SpName:"",
      SpValue: '',
      options: [
        { label: '适老服务', value: '19' },
        { label: '健康助手', value: '4' },
        { label: '车主权益', value: '15' },
      ],
      DateSelect: [],
      shortcuts: [
    {
        text: '今天',
        onClick:(picker: any)  =>  {
            // console.log("picker");
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
        }
    },
    {
        text: '昨天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近7天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近15天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近30天',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // console.log(data.DateSelect);
            // picker.$emit('pick', [start, end]);
        }
    },
    {
        text: '近3月',
        onClick(picker: any) {
            data.DateSelect = []
            let start = new Date()
            let end = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setHours(0, 0, 0)
            end.setHours(23, 59, 59)
            picker.emit('pick',data.DateSelect);
            const startyear = start.getFullYear();
            const startmonth = (start.getMonth() + 1).toString().padStart(2, '0');
            const startday = start.getDate().toString().padStart(2, '0');            
            const endyear = end.getFullYear();
            const endmonth = (end.getMonth() + 1).toString().padStart(2, '0');
            const endday = end.getDate().toString().padStart(2, '0');
            let startstr = startyear+"-"+startmonth+"-"+startday
            let endstr = endyear+"-"+endmonth+"-"+endday
            data.DateSelect.push(startstr)
            data.DateSelect.push(endstr)
            // picker.$emit('pick', [start, end]);
        }
    },
]

        
    });

    const changeClick = () => {
        return false;
    };

    const selectTouSu = (item: any) => { 
        // console.log("DateSelect");
        // console.log(data.DateSelect);
      if(data.DateSelect[0] == ""){
        ElMessageBox.alert("请选择开始日期");
        return;
      }
      // if(data.DateSelect == null){
      //   ElMessageBox.alert("请选择开始日期");
      //   return;
      // }
      if(data.DateSelect[0] === undefined){
        ElMessageBox.alert("请选择开始日期");
        return;
      }
      getComplaintFormStatistics();
      getComplaintFormStatisticsCount();
    };

    const getComplaintFormStatistics = () => {
    
      data.complaintFormStatisticsList = [];
      console.log(data.DateSelect);
      
      // if(data.DateSelect[1] == ""){
      //   ElMessageBox.alert("请选择结束日期");
      //   return;
      // }
      // if(data.DateSelect[0] == ""){
      //   data.DateSelect[0] = '';
      //   data.DateSelect[1] = '';
      // }
      // if(data.DateSelect == null){
      //   data.DateSelect[0] = '';
      //   data.DateSelect[1] = '';
      // }
      // if(data.DateSelect[0] === undefined){
      //   data.DateSelect[0] = '';
      //   data.DateSelect[1] = '';
      // }
      // if(data.SpName == ""){
      //   ElMessageBox.alert("请选择业务名称")
      //   return;
      // }
      let tousudata = {StartDate: data.DateSelect[0], EndDate: data.DateSelect[1],SpId: data.SpValue}
      console.log(tousudata);
      Api.Config.GetComplaintFormStatisticsList(tousudata).then((res: any) => {
        // console.log("res");
        // console.log(res);
        var TotalFee = 0;
        var ExpectedFee = 0;
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){              
                res.Data[i].TotalFee = res.Data[i].TotalFee / 100;
                res.Data[i].ExpectedFee = res.Data[i].ExpectedFee / 100;
                ExpectedFee = ExpectedFee + res.Data[i].ExpectedFee;
                TotalFee = TotalFee + res.Data[i].TotalFee;
                if(res.Data[i].RefundType == 4){
                    res.Data[i].RefundType = "充值退费"
                }
                else if(res.Data[i].RefundType == 5){
                    res.Data[i].RefundType = "当月退费"
                }
                else if(res.Data[i].RefundType == 8){
                    res.Data[i].RefundType = "直接退费"
                }
                data.complaintFormStatisticsList.push(res.Data[i]);
                // list.push(res.Data.data[i])
            }
            var  amount = {PackagesName: '合计', TotalFee: TotalFee, RefundType: '', Operator: '', ExpectedFee: ExpectedFee};
            data.complaintFormStatisticsList.push(amount);
            // console.log("2");
            
        } 
      });
            // console.log(data.complaintFormStatisticsList);
    };

    const getComplaintFormStatisticsCount = () => {
    
      data.complaintFormStatisticsCountList = [];
      // console.log(data.DateSelect[0]);
      // if(data.DateSelect[0] == ""){
      //   ElMessageBox.alert("请选择开始日期");
      //   return;
      // }
      // if(data.DateSelect == null){
      //   ElMessageBox.alert("请选择开始日期");
      //   return;
      // }
      // if(data.DateSelect[0] === undefined){
      //   ElMessageBox.alert("请选择开始日期2");
      //   return;
      // }
      //   if(data.DateSelect[1] == ""){
      //   ElMessageBox.alert("请选择结束日期");
      //   return;
      // }
      // if(data.SpName == ""){
      //   ElMessageBox.alert("请选择业务名称")
      //   return;
      // }
      // if(data.DateSelect[0] == ""){
      //   data.DateSelect[0] = '';
      //   data.DateSelect[1] = '';
      // }
      // if(data.DateSelect == null){
      //   data.DateSelect[0] = '';
      //   data.DateSelect[1] = '';
      // }
      // if(data.DateSelect[0] === undefined){
      //   data.DateSelect[0] = '';
      //   data.DateSelect[1] = '';
      // }
      let tousudata = {StartDate: data.DateSelect[0], EndDate: data.DateSelect[1],SpId: data.SpValue}
      console.log(tousudata);
      Api.Config.GetComplaintFormStatisticsCountList(tousudata).then((res: any) => {
        // console.log("res");
        // console.log(res);
        var Count = 0;
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){
              Count = Count + res.Data[i].Count;
                // res.Data[i].TotalFee = res.Data[i].TotalFee / 100;
                // res.Data[i].ExpectedFee = res.Data[i].ExpectedFee / 100;
                if(res.Data[i].RefundType == 4){
                    res.Data[i].RefundType = "充值退费"
                }
                else if(res.Data[i].RefundType == 5){
                    res.Data[i].RefundType = "当月退费"
                }
                else if(res.Data[i].RefundType == 8){
                    res.Data[i].RefundType = "直接退费"
                }
                data.complaintFormStatisticsCountList.push(res.Data[i]);
                // list.push(res.Data.data[i])
            }
            var  amount = {RefundType: '合计', Operator: '', Count: Count}
            data.complaintFormStatisticsCountList.push(amount);
            // console.log("2");
            
        } 
      });
            console.log(data.complaintFormStatisticsCountList);
    };
    const selectSpName = (item: any) => {
      // console.log("label");
      // console.log(item);
        data.SpValue = parseInt(item.value);
        data.SpName = item.label;
        // console.log(data.SpName);
        
    };
    const ChaKanTouSuRef = ref<InstanceType<typeof ChaKanTouSu>>();
    const SetTouSu = (row: any) => {
      data.tousuDialogVisible = true;
      let tousuRow = {phone: row.Phone, packagesId: row.PackagesId}
      // console.log("tousuRow");
      // console.log(row);
      ChaKanTouSuRef.value?.searchChange(tousuRow);
    };
    onMounted(() => {
        // getComplaintFormStatistics();
        
        // console.log(proxy);
    });

    return {
      ...toRefs(data),
      activeName: 'fee',
      getComplaintFormStatistics,
      getComplaintFormStatisticsCount,
      selectTouSu,
      SetTouSu,
      changeClick,
      ChaKanTouSuRef,
      selectSpName,
    //   handlePick,
    };
  },
});
